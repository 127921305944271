/*
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-01-16 10:38:41
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-01-16 10:39:20
 */
import * as inter from "./modules/interface";
const http = {
  ...inter,
};
export default http;