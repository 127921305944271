import http from "../request";
const prefix = "/screen";

/**
 * 主屏
 */
export const todayCount = (data)=>{
  //今日统计数量
  return http.get(`${prefix}/getTodayStatisticsCount`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 电梯总量
 */
export const getTotalElevators = (data)=>{
  return http.get(`${prefix}/getTotalElevators`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 故障统计
 */
export const getFaultStatisticsByDate = (data)=>{
  return http.get(`${prefix}/getFaultStatisticsByDate`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 设备报警次数
 */
export const getEquipmentAlarmCount = (data)=>{
  return http.get(`${prefix}/getEquipmentAlarmCount`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 预警记录
 */
export const getAllWarningRecords = (data)=>{
  return http.get(`${prefix}/getAllWarningRecords`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 设备预警⻛险平均值
 */
export const getAverageEquipmentWarningRisk = (data)=>{
  return http.get(`${prefix}/getAverageEquipmentWarningRisk`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 重点保养
 */
export const getKeyMaintenancePlans = (data)=>{
  return http.get(`${prefix}/getKeyMaintenancePlans`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 未来24⼩时故障预警
 */
export const getFuture24HourFaultWarnings= (data)=>{
  return http.get(`${prefix}/getFuture24HourFaultWarnings`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 电梯基本信息展示
 */
export const getElevatorInformation= (data)=>{
  return http.post(`${prefix}/getElevatorInformation`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 电梯预警记录
 */
export const getAllWarningRecordsById= (data)=>{
  return http.post(`${prefix}/getAllWarningRecordsById`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 电梯预警⻛险值
 */
export const getDeviceWarningRiskById= (data)=>{
  return http.post(`${prefix}/getDeviceWarningRiskById`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 通过SSE实时返回电梯运⾏数据
 */
export const createSse= (data)=>{
  return http.get(`/sse/createSse`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}

/**
 * 断开SSE连接
 */
export const breakSse= (data)=>{
  return http.get(`/sse/closeSse`,data,{
    headers:{
      "Content-Type":"application/json"
    }
  })
}