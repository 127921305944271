/*
 * @Description: 
 * @Author: FeiShu
 * @Date: 2022-05-27 11:13:29
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-11 10:52:37
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DataV from '@jiaminghi/data-view';
import "@a/fonts/font.css"
import "@a/iconfont/iconfont.css";
// import './utils/rem.js';
import '@a/css/base.scss';
import 'swiper/dist/css/swiper.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import http from "@/http";
import CountTo from 'vue-count-to';

// 如果需要全局注册 Swiper 组件
Vue.use(VueAwesomeSwiper)
Vue.prototype.$http=http;
Vue.config.productionTip = false
Vue.use(DataV)
Vue.use(ElementUI);
Vue.component('CountTo', CountTo)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
