<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2022-05-27 11:13:29
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-11 11:04:09
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import autofit from "autofit.js";
export default {
  data() {
    return {};
  },
  mounted() {
    autofit.init({
      dw: 1920,
      dh: 1080,
      el: "#app",
      resize: true,
    });
  },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    display: none;
    background-color: transparent;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
</style>
