<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-04 14:22:57
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-04 14:55:12
-->
<template>
  <div class="header">
  
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  background: url('~@a/images/header.png') no-repeat;
  background-size: 100%;
  background-position: 0 0;
}
</style>