/*
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-01-10 15:30:40
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-04 13:56:55
 */
import Vue from 'vue'
import Vuex from 'vuex'
import Http from "@/http"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
