<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2022-05-27 11:43:19
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-11 10:37:25
-->
<template>
  <div class="layout">
    <Header></Header>
    <router-view />
  </div>
</template>

<script>
import Header from "@c/commonHeader"
export default {
  components: {
    Header
  },
  data () {
    return {
      
    }
  },
  created () {
    
  },
  methods: {
    
  },
  beforeDestroy () {
    
  }
}
</script>

<style lang="scss" scoped>
.layout {
  // width: 1920px;
	// height: 1080px;
  width: 100%;
  height: 100%;
  background: url('~@a/images/bg.png') no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  overflow: auto;
}
</style>