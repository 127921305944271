/*
 * @Description:
 * @Author: FeiShu
 * @Date: 2021-10-26 16:08:20
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-06 14:55:07
 */
import axios from "axios";
import { clearLocalStorage } from "@/utils/storage";
import router from "@/router/index";
import { Message } from 'element-ui';

const config = {
  // 默认地址请求地址，可在 .env 开头文件中修改
  baseURL: BASE_API[ process.env.NODE_ENV ],
  // 设置超时时间（10s）
  timeout: 10000,
  // 跨域时候允许携带凭证
  withCredentials: true,
};
class RequestHttp {
  constructor(configData) {
    this.service = axios.create(configData);
    this.service.interceptors.request.use(
      (config) => {
        //TODO删
        // const storage = localStorage.getItem("storageInfo");
        // console.log("storage", storage);
        // const storageInfo = storage ? JSON.parse(storage) : {};
        const szUserInfoNew = JSON.parse(
          localStorage.getItem("szUserInfoNew") || "{}"
        );
        const token = localStorage.getItem('screenToken') || '';
        return {
          ...config,
          headers: {
            // token: storageInfo?.token,
            // loginUser: storageInfo?.loginUser,
            // subjectId:"13644885554",
            //TODO删
            // "X-Supervision-Auth":localStorage.getItem("X-Supervision-Auth"),
            orgCode: szUserInfoNew.orgCode || '',
            subjectId: szUserInfoNew.subjectId || '',
            token: token ? token : szUserInfoNew.token,
            ...config.headers,
          },
        };
      },
      (error) => {
        Promise.reject(error);
      }
    );
    this.service.interceptors.response.use(
      (response) => {
        //   const res: ResponseItem = response.data
        const { data, config } = response;
        if (data.code === 1 || data.code === 200 || data.success) {
          return Promise.resolve(data);
        } else if (data.code == '02') {
          // clearLocalStorage('X-Supervision-Auth');
          // clearLocalStorage('__userInfo__');
          // Toast.fail(data.message);
          Message.error(data.message);
          // window.location.href = backUrl;
        } else {
          Message.error(data.message);
          return Promise.resolve(data);
        }
      },
      (error) => {
        const { response } = error;
        Message.error("请求超时！请您稍后重试")
        return Promise.reject(error);
      }
    );
  }

  // * 常用请求方法封装
  get(url, params, _object = {}) {
    return this.service.get(url, { params, ..._object });
  }

  post(url, params, _object = {}) {
    return this.service.post(url, params, _object);
  }

  put(url, params, _object = {}) {
    return this.service.put(url, params, _object);
  }

  delete(url, params, _object = {}) {
    return this.service.delete(url, { params, ..._object });
  }
}

export default new RequestHttp(config);
