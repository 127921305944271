/*
 * @Description: 
 * @Author: FeiShu
 * @Date: 2022-05-27 11:13:29
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-05 14:14:31
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/index',
    component: Layout,
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index')
      },
      {
        path: '/detail',
        name: 'Detail',
        component: () => import(/* webpackChunkName: "detail" */ '../views/detail')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
